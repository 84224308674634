<template>
    <v-app>
        <!-- View Container -->
        <div class="w-100" style="z-index: 100">
            <v-toolbar flat height="75px" color="background" class="pr-5">
                <div class="d-flex align-center cursor-pointer" @click="goToHome()">
                    <img :height="`${xs ? '40px' : '75px'}`" src="/images/Icon.png" alt="Logo" :class="`${xs ? 'mx-1' : 'mx-4'}`" />
                </div>

                <v-spacer></v-spacer>

                <div v-if="currentWindowWidth > 900" :class="`d-flex`">
                    <nav>
                        <nuxt-link to="/" class="toolbarLink mx-3 text-black"> Home </nuxt-link>
                        <nuxt-link @click="openUrlToIndicators()" class="toolbarLink mx-3 cursor-pointer text-black"> Indicators </nuxt-link>
                        <nuxt-link to="/contactUs" class="toolbarLink mx-3 text-black"> Contact Us </nuxt-link>
                    </nav>
                </div>

                <div v-else>
                    <div class="text-center">
                        <v-menu :close-on-content-click="false" min-width="300" max-width="500">
                            <template v-slot:activator="{ props, isActive }">
                                <v-btn icon v-bind="props">
                                    <v-icon>i-mdi:menu</v-icon>
                                </v-btn>
                            </template>

                            <v-list style="width: 170px">
                                <v-list-item>
                                    <nuxt-link to="/" class="toolbarLink mx-3 text-black"> Home </nuxt-link>
                                </v-list-item>

                                <v-list-item>
                                    <nuxt-link @click="openUrlToIndicators()" class="toolbarLink cursor-pointer mx-3 text-black">
                                        Indicators
                                    </nuxt-link>
                                </v-list-item>

                                <v-list-item>
                                    <nuxt-link to="/contactUs" class="toolbarLink mx-3 text-black"> Contact Us </nuxt-link>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </div>

                <!-- <v-dialog transition="scale-transition" origin="center center" max-width="500" v-model="authDialog">
        
                </v-dialog> -->
            </v-toolbar>

            <!-- <div class="svgPattern01 w-100" style="height: 150px"></div> -->
            <!-- <v-divider v-if="$nuxt.$route.name === 'contact'"></v-divider> -->
        </div>

        <v-main>
            <!--  -->
            <v-container :fluid="currentPath !== 'contact'" :class="`${currentPath !== 'contact' ? 'pa-0' : 'reducedContainer'}`">
                <slot />
            </v-container>
        </v-main>
        <v-footer color="primary" :class="[xs ? 'pa-0' : 'px-3']" paddless style="margin-top: 3rem; display: flex; justify-content: space-between">
            <div v-if="!smAndDown" style="width: 150px" />
            <v-sheet color="primary" class="text-center">
                <div class="py-3 d-flex" :class="[xs ? 'ga-1' : 'ga-3']">
                    <v-tooltip location="top" v-for="(icon, i) in icons" :key="i">
                        <template v-slot:activator="{ props }">
                            <v-btn @click="openSocialURL(icon)" color="white" variant="text" v-bind="props" :size="xs ? 'small' : undefined">
                                <v-icon :size="`${xs ? '18' : '20'}px`">
                                    {{ icon.icon }}
                                </v-icon>
                            </v-btn>
                        </template>
                        <span class="caption font-weight-bold">{{ icon.label }}</span>
                    </v-tooltip>

                    <!-- <v-tooltip offset="15" location="top">
                        <template v-slot:activator="{ props }">
                            <v-btn
                                v-bind="props"
                                @click="isPowerOn = !isPowerOn"
                                :disabled="isAnyLoading || isInvokeControlDisabled"
                                :color="`${isPowerOn ? 'error' : 'primary'}`"
                                size="40"
                                class=""
                                style="opacity: 0.85"
                            >
                                <v-icon v-if="!(isAnyLoading || isInvokeControlDisabled)" icon="i-mdi:power" size="22" />
                                <SvgSpinners90RingWithBg v-else class="text-h5 text-primary" />
                            </v-btn>
                        </template>
                        <div class="font-weight-medium text-caption text-center" style="opacity: 0.9">
                            {{ t('general.ac.controller.toggleControl') }}
                        </div>
                    </v-tooltip> -->
                </div>
            </v-sheet>
            <div class="font-weight-bold pa-5 text-center" :class="[smAndDown ? 'text-caption' : 'text-subtitle-2']">
                RimbaWatch Sdn Bhd (1504929-M)
            </div>
        </v-footer>

        <BaseSnackbar ref="snackbarRef" :snackConfig="snackConfig" />
    </v-app>
</template>

<script setup lang="ts">
// Theme Toggle
const vTheme = useTheme();
const isDarkTheme = computed(() => vTheme.global.current.value.dark);
const { name, xs, smAndDown } = useDisplay();

const snackbarRef = ref();
const snackConfig = ref({
    snackTimeout: 5000,
    snackColor: "error",
    snackIcon: "i-mdi:alert",
    snackTitle: "",
    snackContent: "",
});

const currentWindowWidth = ref(500);
const icons = ref([
    { icon: "i-mdi:linkedin", label: "LinkedIn" },
    { icon: "i-mdi:twitter", label: "Twitter" },
    { icon: "i-mdi:instagram", label: "Instagram" },
]);

onMounted(() => {
    if (currentWindowWidth.value !== null) {
        currentWindowWidth.value = window.innerWidth;
        window.addEventListener("resize", () => {
            currentWindowWidth.value = window.innerWidth;
        });
    }
});

const route = useRoute();
const currentPath = computed(() => route.path);

// const openAuthDialog = () => {
//     authDialog.value = true;
// };

const toggleAPI = () => {
    // if (this.email !== "nick" && this.password !== "123") {
    //   this.snackbar = true;
    // } else {
    //   this.snackbar = false;
    //   this.toggleAPI();
    // }
    // this.toggleAPI();
};

const goToHome = () => {
    navigateTo("/");
};

const openSocialURL = (icon: { icon: string; label: string }) => {
    switch (icon.icon) {
        case "i-mdi:twitter":
            window.open("https://twitter.com/RimbaWatch");
            break;
        case "i-mdi:linkedin":
            window.open("https://www.linkedin.com/company/90648299");
            break;
        case "i-mdi:instagram":
            window.open("https://www.instagram.com/rimbawatch/");
            break;
        default:
            break;
    }
};

const openUrlToIndicators = () => {
    window.open("https://rimbawatchmy.com/databases");
};

// ----------------- Error Catch Handler & Loading State -----------------
</script>

<style>
@media (min-width: 1904px) {
    .container.reducedContainer {
        max-width: 1385px !important;
    }
}

.toolbarLink {
    text-decoration: none;
}

a.router-link-active {
    font-weight: bold;
}
</style>
